import { IPublicClientApplication } from '@azure/msal-browser/dist/app/IPublicClientApplication'
import { useAppFolder } from '../authConfig'

const scopes = useAppFolder ? ['Files.ReadWrite.AppFolder'] : ['Files.ReadWrite']

const key1 = 'accessToken'
const key2 = 'accessTokenExpire'

function getStoredAccessToken () {
  const accessTokenExpire = localStorage.getItem(key2)

  const now = new Date()
  const expDate = accessTokenExpire ? new Date(accessTokenExpire) : now
  const isExpired = expDate < now

  if (isExpired) {
    localStorage.removeItem(key1)
    localStorage.removeItem(key2)
  }

  return isExpired ? null : localStorage.getItem(key1)
}

function storeAccessToken (accessToken: string | null, expiresOn: Date | null) {
  if (accessToken && expiresOn && expiresOn.getDate() > Date.now()) {
    localStorage.setItem(key1, accessToken)
    localStorage.setItem(key2, expiresOn.toString())
  }
  return accessToken || null
}

export default async function getAccessToken (instance: IPublicClientApplication): Promise<string | null> {
  const account = instance.getActiveAccount()
  if (!account) {
    return null
  }

  const storedToken = getStoredAccessToken()
  if (storedToken) {
    console.log('use stored token')
    return storedToken
  }

  const redirectResponse = await instance.handleRedirectPromise()
  if (redirectResponse !== null) {
    // Acquire token with redirect success
    return storeAccessToken(redirectResponse.accessToken, redirectResponse.expiresOn)
  }

  try {
    const tok = await instance.acquireTokenSilent({ scopes, account })
    return storeAccessToken(tok.accessToken, tok.expiresOn)
  } catch (err1) {
    console.log(`could not acquire token using silent method [${err1}]`)
    await instance.acquireTokenRedirect({ scopes, account })
    return null
  }
}
