import { IPublicClientApplication } from '@azure/msal-browser/dist/app/IPublicClientApplication'
import { DriveFile, DriveFolder } from '../utils/types'
import { startUploadInSpecialDir, startUploadSession } from '../utils/graph_client'
import axios from 'axios'
import { memo, useEffect, useRef, useState } from 'react'
import { UploadItem, UploadItemCancelButton, UploadItemLabel, UploadItemProgress } from '../styles/styled'
import { useAppFolder } from '../authConfig'

const chunkSize = 327680 // required by MS to be implicitly this size

interface FileUploaderProps {
  fake?: boolean
  file: File
  folder?: DriveFolder
  instance: IPublicClientApplication
  onUploadError?: (localFile: File, err: Error) => void
  onUploadComplete?: (localFile: File, driveFile: DriveFile) => void
}

function UploadProgress (props: FileUploaderProps) {
  const { file } = props
  const total = file.size
  const [uploaded, setUploaded] = useState(0)
  const [uploadUrl, setUploadUrl] = useState<string>()
  const { current: state } = useRef({
    failed: false,
    uploaded: false,
    finished: false,
    cancelled: false
  })

  const onCancelClick = () => {
    state.cancelled = true
    state.finished = true
  }

  const onSuccess = (remoteFile: DriveFile) => {
    if (!state.uploaded && props.onUploadComplete) {
      props.onUploadComplete(file, remoteFile)
    }
    state.uploaded = true
    state.finished = true
  }

  const onError = (e: Error) => {
    if (!state.failed && props.onUploadError) {
      props.onUploadError(file, e)
    }
    state.failed = true
    state.finished = true
  }

  const readChunk = (from: number, to: number): Promise<ArrayBuffer> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onerror = (evt) => {
        reject(new Error(`${evt}`))
      }

      reader.onload = () => {
        if (reader.result) {
          resolve(reader.result as ArrayBuffer)
        } else {
          reject(new Error(`Unable to read ${file.name}`))
        }
      }

      reader.readAsArrayBuffer(file.slice(from, to))
    })
  }

  const uploadChunk = async (from: number, to: number, chunk: ArrayBuffer) => {
    if (!uploadUrl) {
      throw new Error('Session not initialised')
    }

    const rangeHeader = `bytes ${from}-${to - 1}/${total}`
    const headers = { 'Content-Range': rangeHeader }
    return await axios.put(uploadUrl, chunk, { headers })
  }

  const processChunk = (from: number) => {
    setTimeout(async () => {
      try {
        if (props.fake) {
          setUploaded(Math.random() * total)
          return
        }

        if (state.cancelled) {
          return onError(new Error('Cancelled by user'))
        }

        const to = Math.min(from + chunkSize, total)

        const chunk = await readChunk(from, to)
        if (state.cancelled) {
          return onError(new Error('Cancelled by user'))
        }

        const res = await uploadChunk(from, to, chunk)

        if (state.cancelled) {
          onError(new Error('Cancelled by user'))
        } else if (to >= total) {
          onSuccess(res.data as DriveFile)
        } else {
          setUploaded(to)
          processChunk(to)
        }
      } catch (e) {
        onError(e as Error)
      }
    }, 0)
  }

  useEffect(() => {
    if (uploadUrl) {
      processChunk(0)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploadUrl])

  useEffect(() => {
    if (props.fake) {
      setUploadUrl('fake')
      return
    }

    const onUploadStarted = (url: string | null) => {
      if (url) {
        console.log(`upload url for ${file.name} = ${url}`)
        setUploadUrl(url)
      } else {
        onError(new Error('Unable to start upload'))
      }
    }

    if (useAppFolder) {
      startUploadInSpecialDir(props.instance, file).then(onUploadStarted)
    } else if (props.folder) {
      startUploadSession(props.instance, props.folder.id, file)
        .then(onUploadStarted)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <UploadItem>
    <UploadItemLabel title={file.name}>{file.name}</UploadItemLabel>

    <UploadItemCancelButton type="reset" onClick={onCancelClick}>
      <img src="/Cancelcon.svg" alt={`Cancel upload of ${file.name}`}/>
    </UploadItemCancelButton>

    <UploadItemProgress value={uploaded} max={total} />
  </UploadItem>
}

export default memo(UploadProgress, (prev, next) => {
  return prev.file === next.file && (useAppFolder || prev.folder?.id === next.folder?.id)
})
