import axios from 'axios'
import getAccessToken from './get_access_token'
import { IPublicClientApplication } from '@azure/msal-browser/dist/app/IPublicClientApplication'
import { DriveFolder, DriveItem } from './types'
import { useAppFolder } from '../authConfig'

export const inboxFolderName = 'inbox'
export let inboxFolder: DriveFolder | null = null

export const base = 'https://graph.microsoft.com/v1.0'

async function getHeaders (instance: IPublicClientApplication) {
  const accessToken = await getAccessToken(instance)
  if (!accessToken) {
    throw new Error('Unauthorized')
  }
  return { Authorization: `Bearer ${accessToken}` }
}

export async function graphGet (instance: IPublicClientApplication, url: string) {
  console.log(`doing GET request to ${url}`)
  const headers = await getHeaders(instance)
  return await axios.get(url, { headers })
}

export async function graphPost (instance: IPublicClientApplication, url: string, data: any) {
  console.log(`doing POST request to ${url}`)
  const headers = await getHeaders(instance)
  return await axios.post(url, data, { headers })
}

export async function getMyDriveContents (instance: IPublicClientApplication): Promise<DriveItem[]> {
  if (useAppFolder) {
    return []
  }

  try {
    const res = await graphGet(instance, `${base}/me/drive/root/children`)
    return res.data.value
  } catch (e) {
    console.error(`Unable to list user drives: ${e}`)
    return []
  }
}

export async function createFolderInRoot (instance: IPublicClientApplication, name: string) {
  const res = await graphPost(instance, `${base}/me/drive/root/children`, {
    name,
    folder: {},
    '@microsoft.graph.conflictBehavior': 'rename'
  })
  return res.data as DriveFolder
}

export async function getMyDriveWithInbox (instance: IPublicClientApplication): Promise<DriveFolder[]> {
  if (useAppFolder) {
    return []
  }
  const contents = await getMyDriveContents(instance)
  const folders = contents.filter(item => !!item.folder) as DriveFolder[]

  inboxFolder = folders.find(f => f.name === inboxFolderName) || null

  if (!inboxFolder) {
    console.log(`will have to create folder named "${inboxFolderName}"`)
    inboxFolder = await createFolderInRoot(instance, inboxFolderName)
    folders.push(inboxFolder)
  }

  return folders
}

async function uploadFileByUrl (instance: IPublicClientApplication, url: string, file: File): Promise<string | null> {
  const res = await graphPost(instance, url, {
    '@microsoft.graph.conflictBehavior': 'replace',
    description: 'File uploaded with React tool',
    name: file.name
  })
  return res.data?.uploadUrl || null
}

export async function startUploadInSpecialDir (instance: IPublicClientApplication, file: File) {
  return await uploadFileByUrl(instance, `${base}/drive/special/approot:/${file.name}:/createUploadSession`, file)
}

export async function startUploadSession (instance: IPublicClientApplication, folderId: string, file: File): Promise<string | null> {
  return await uploadFileByUrl(instance, `${base}/me/drive/items/${folderId}:/${file.name}:/createUploadSession`, file)
}
