import { DriveFolder } from '../utils/types'
import { UploadFormFileInput, UploadFormLabel, UploadFormWrapper } from '../styles/styled'
import { useAppFolder } from '../authConfig'

interface UploadFormProps {
  folder?: DriveFolder
  onFilesSelected: (files: File[]) => void
}

export default function UploadForm (props: UploadFormProps) {
  const { folder, onFilesSelected } = props

  if (!folder && !useAppFolder) {
    return <UploadFormWrapper />
  }

  const folderId = folder?.id || 'sandbox'
  const selectId = `upload_to_${folderId}_${Math.random().toString().substring(2)}`

  return <UploadFormWrapper>
    <UploadFormFileInput
      id={selectId}
      type="file"
      multiple
      onChange={e => {
        if (e.target.files?.length) {
          onFilesSelected(Array.from(e.target.files))
        }
      }}
    />

    <UploadFormLabel htmlFor={selectId}>Select files</UploadFormLabel>
  </UploadFormWrapper>
}
