import { MsalProvider, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'
import React, { useEffect, useRef, useState } from 'react'
import type { IPublicClientApplication } from '@azure/msal-browser/dist/app/IPublicClientApplication'
import { loginRequest, redirectPath, useAppFolder } from './authConfig'
import { DriveFile, DriveFolder, DriveItem } from './utils/types'
import { base, getMyDriveWithInbox, graphGet, inboxFolder, inboxFolderName } from './utils/graph_client'
import {
  ContentMid,
  GlobalStyle,
  HeadLeft,
  InlineDiv,
  OrangeButton,
  LogOutWrapper,
  PageContent,
  PageHead,
  PageWrapper,
  Right, FileTable
} from './styles/styled'
import MyFolders from './components/MyFolders'
import FolderContents from './components/FolderContents'
import UploadForm from './components/UploadForm'
import Uploads from './components/Uploads'
import NitrexLogo from './components/NitrexLogo'
import { AccountInfo } from '@azure/msal-browser'

type AppProps = {
  instance: IPublicClientApplication
}

export default function App ({ instance }: AppProps) {
  const [account, setAccount] = useState<AccountInfo | null>(null)
  const [folders, setFolders] = useState<DriveItem[]>([])
  const [folderContents, setFolderContents] = useState<DriveItem[]>([])
  const [uploads, setUploads] = useState<File[]>([])
  const [uploaded, setUploaded] = useState<File[]>([])
  const [folder, setFolder] = useState<DriveFolder>()
  const [folderError, setFolderError] = useState<Error | null>(null)
  const loginWrapper = useRef<HTMLDivElement | null>(null)

  const onLogInClick = () => {
    instance.loginRedirect(loginRequest).catch(error => console.log(`Unable to log in: ${error}`))
    return false
  }

  const onLogOutClick = () => {
    instance.logoutRedirect().catch(error => console.log(`Unable to log out: ${error}`))
  }

  useEffect(() => {
    const id = setTimeout(() => {
      if (instance && loginWrapper.current) {
        setTimeout(onLogInClick, 200)
      }
    }, 250)

    return () => {
      if (id) {
        clearTimeout(id)
      }
    }
  }, [])

  useEffect(() => {
    let id: NodeJS.Timeout | null = null

    const stopTimer = () => {
      if (id) {
        clearInterval(id)
        id = null
      }
    }

    let attempts = 0

    if (!account) {
      id = setInterval(() => {
        if (attempts > 100) {
          stopTimer()
          console.log(`stopped trying after ${attempts} attempts`)
        } else if (!account) {
          attempts++
          const activeAccount = instance.getActiveAccount()
          if (activeAccount) {
            stopTimer()
            setAccount(activeAccount)
          }
        } else {
          stopTimer()
        }
      }, 150)
    }

    return () => {
      stopTimer()
    }
  }, [])

  useEffect(() => {
    if (account && window.location.pathname === redirectPath) {
      window.location.pathname = '/'
    }
  }, [account, window.location.pathname])

  useEffect(() => {
    if (folders.length && inboxFolder) {
      const inbox = folders.find(f => f.folder && f.id === inboxFolder?.id)
      setFolder(inbox as DriveFolder || undefined)
    } else if (account && !useAppFolder) {
      getMyDriveWithInbox(instance)
        .then(setFolders)
        .catch(setFolderError)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [folders, account])

  useEffect(() => {
    setUploads([])
    setUploaded([])
    if (folder) {
      console.log(`Navigated to folder "${folder?.name}"`)
    } else if (useAppFolder) {
      graphGet(instance, `${base}/drive/special/approot`)
        .then(res => {
          console.log('approot meta', res.data)
        })
        .catch(err => {
          console.log(`err ${err}`)
        })
    }
  }, [folder])

  const excludeFileFromUploads = (localFile: File) => {
    setUploaded(prev => [...prev, localFile])
  }

  const onFileUploaded = (localFile: File, remoteFile: DriveFile) => {
    console.log('uploaded a file', remoteFile)

    if (!folderContents.some(f => f.id === remoteFile.id)) { // prevent duplicates
      setFolderContents(prev => [...prev, remoteFile])
    }
    excludeFileFromUploads(localFile)
  }

  const onFileNotUploaded = (localFile: File, error: Error) => {
    excludeFileFromUploads(localFile)
    console.log(error)
  }

  const onFilesSelectedForUpload = (files: File[]) => {
    try {
      console.log('selected files', files)
      console.log('uploads', uploads)
      console.log('uploaded', uploaded)

      const newFiles = files.filter(f => !uploads.includes(f) && !uploaded.includes(f))
      console.log('newFiles', newFiles)

      if (newFiles.length) {
        setUploads(uploads.concat(newFiles))
      }
    } catch (e) {
      console.error(e)
    }
  }

  return <MsalProvider instance={instance}>
    <div>
      <AuthenticatedTemplate>
        {!!account && <PageWrapper>
          <PageHead className="head">
            {!useAppFolder && <HeadLeft className="left">
              <NitrexLogo />
              <div className="upper">My drive</div>
            </HeadLeft>}

            {!useAppFolder && <InlineDiv>
              <div className="upper">{document.title}</div>
              <div className="upper">{folder?.name} contents</div>
            </InlineDiv>}

            {useAppFolder && <InlineDiv>
              <NitrexLogo />
              <div className="upper">{document.title}</div>
            </InlineDiv>}

            <Right>
              <LogOutWrapper>
                <span>{account.name}</span>
                <OrangeButton onClick={onLogOutClick}>Sign out</OrangeButton>
              </LogOutWrapper>

              <div>UPLOADS</div>
            </Right>
          </PageHead>

          <PageContent>
            {!useAppFolder && <MyFolders
              folders={folders}
              folder={folder}
              onFolderSelected={setFolder}
            />}

            {!folderError && <ContentMid>
              {/* this component uses shared state with App */}
              <FolderContents
                folder={folder}
                setFolder={setFolder}
                folderContents={folderContents}
                setFolderContents={setFolderContents}
              />

              <UploadForm folder={folder} onFilesSelected={onFilesSelectedForUpload} />
            </ContentMid>}

            {folderError && <ContentMid>
              <FileTable>
                <tbody>
                  <tr>
                    <td className="center">
                      You don't have permissions to use / create your "{inboxFolderName}" folder. Please contact
                      administrator
                    </td>
                  </tr>
                </tbody>
              </FileTable>
            </ContentMid>}

            <Uploads
              folder={folder}
              uploads={uploads}
              uploaded={uploaded}
              onFileUploaded={onFileUploaded}
              onFileNotUploaded={onFileNotUploaded}
            />
          </PageContent>
        </PageWrapper>}
      </AuthenticatedTemplate>

      <UnauthenticatedTemplate>
        <PageWrapper>
          <PageHead className="head">
            <HeadLeft className="left">
              <NitrexLogo />
              <div>&nbsp;</div>
            </HeadLeft>
          </PageHead>

          <PageContent>
            <div ref={loginWrapper} style={{ textAlign: 'center', width: '100%', padding: '2em' }}>
              <p>Please <a onClick={onLogInClick}>sign in</a> first</p>
            </div>
          </PageContent>
        </PageWrapper>
      </UnauthenticatedTemplate>

      <GlobalStyle />
    </div>
  </MsalProvider>
}
