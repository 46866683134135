import FileUploader from './UploadProgress'
import { DriveFile, DriveFolder } from '../utils/types'
import { useMsal } from '@azure/msal-react'
import { Right, UploadsWrapper } from '../styles/styled'

interface UploadsProps {
  folder?: DriveFolder
  uploads: File[]
  uploaded: File[]
  fake?: boolean

  onFileUploaded: (localFile: File, remoteFile: DriveFile) => void
  onFileNotUploaded: (localFile: File, error: Error) => void
}

export default function Uploads (props: UploadsProps) {
  const { instance } = useMsal()
  const { folder, uploads, uploaded } = props

  const toRender = uploads.filter(file => !uploaded.includes(file))

  return <UploadsWrapper className={`col ${Right}`}>
    {toRender.map(file => <FileUploader
      key={file.webkitRelativePath + file.lastModified}
      file={file}
      folder={folder}
      instance={instance}
      fake={props.fake}
      onUploadComplete={props.onFileUploaded}
      onUploadError={props.onFileNotUploaded}
    />)}
  </UploadsWrapper>
}
