import { DriveFolder, DriveItem } from '../utils/types'
import FileSizeFormat from '@w0s/file-size-format'
import moment from 'moment'
import { FileTable, FileTableWrapper, OrangeButton } from '../styles/styled'
import { useEffect, useState, Dispatch, SetStateAction } from 'react'
import { useMsal } from '@azure/msal-react'
import { base, graphGet } from '../utils/graph_client'
import { useAppFolder } from '../authConfig'

interface FolderContentsProps {
  folder?: DriveFolder
  setFolder: Dispatch<SetStateAction<DriveFolder | undefined>>
  folderContents: DriveItem[]
  setFolderContents: Dispatch<SetStateAction<DriveItem[]>>
}

export default function FolderContents (props: FolderContentsProps) {
  const { instance } = useMsal()
  const {
    folder,
    setFolder,
    folderContents,
    setFolderContents
  } = props
  const [loading, setLoading] = useState(false)
  const [nextLink, setNextLink] = useState('')

  const loadByLink = (url: string, append: boolean) => {
    if (instance && folder) {
      if (!append) {
        setLoading(true)
      }

      graphGet(instance, url)
        .then(response => {
          setNextLink(response.data['@odata.nextLink'] || '')

          const loadedItems = response.data.value as DriveItem[]
          const newItems = append ? folderContents.concat(loadedItems) : loadedItems
          setFolderContents(newItems)
        })
        .finally(() => setLoading(false))
    }
  }

  useEffect(() => {
    if (folder) {
      setNextLink('')
      if (useAppFolder) {
        loadByLink(`${base}/drive/special/approot`, false)
      } else {
        loadByLink(`${base}/me/drive/items/${folder.id}/children?$top=10`, false)
      }
    }
  }, [folder])

  return <FileTableWrapper>
    <FileTable cellPadding={0} cellSpacing={0}>
      <thead>
        <tr>
          <td>Name</td>
          <td width="150">Size</td>
          <td width="240">Kind</td>
          <td width="340">Date added</td>
        </tr>
      </thead>

      <tbody>
        {loading && <tr>
          <td className="center" colSpan={4}>Loading...</td>
        </tr>}

        {!loading && !folderContents.length && <tr>
          <td className="center" colSpan={4}>No files</td>
        </tr>}

        {!loading && folderContents.map(item => <tr key={item.id}>
          {item.file && <td><a href={item.webUrl} target="_blank" rel="noreferrer">{item.name}</a></td>}
          {item.folder && <td style={{ cursor: 'pointer' }} onClick={() => setFolder(item as DriveFolder)}>{item.name}</td>}
          <td>{item.folder ? '-' : FileSizeFormat.si(item.size)}</td>
          <td>{item.file?.mimeType || (item.folder ? 'Folder' : '-')}</td>
          <td>{moment(item.fileSystemInfo.createdDateTime).toLocaleString()}</td>
        </tr>)}
      </tbody>
    </FileTable>

    {nextLink && <p style={{ textAlign: 'center', padding: '20px' }}>
      <OrangeButton onClick={() => loadByLink(nextLink, true)}>More results</OrangeButton>
    </p>}
  </FileTableWrapper>
}
