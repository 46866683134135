import { DriveFolder } from '../utils/types'
import { Folder, LeftCol, SelectedFolder } from '../styles/styled'
import { Dispatch, SetStateAction } from 'react'
import { inboxFolder } from '../utils/graph_client'

interface MyFoldersProps {
  folders: DriveFolder[]
  folder?: DriveFolder
  showAll?: boolean
  onFolderSelected: Dispatch<SetStateAction<DriveFolder | undefined>>
}

export default function MyFolders (props: MyFoldersProps) {
  const { folder, folders, onFolderSelected } = props

  if (!folder || !folders.length) {
    return <LeftCol className="col">&nbsp;</LeftCol>
  }

  if (!props.showAll) {
    const parent = !!inboxFolder && !!folder && folder.id !== inboxFolder.id
      ? ((folder.parentReference || inboxFolder) as unknown as DriveFolder)
      : null

    if (parent) {
      return <LeftCol className="col">
        <Folder style={{ cursor: 'pointer' }} onClick={() => onFolderSelected(parent)}>&uarr; {parent.name}</Folder>
        <SelectedFolder key={folder.id}>{folder.name}</SelectedFolder>
      </LeftCol>
    }

    return <LeftCol className="col">
      <Folder>&nbsp;</Folder> {/* to maintain same height as table header */}
      <SelectedFolder key={folder.id}>{folder.name}</SelectedFolder>
    </LeftCol>
  }

  return <LeftCol className="col">
    <Folder>&nbsp;</Folder> {/* to maintain same height as table header */}

    {folders.map(folder => {
      const onClick = () => onFolderSelected(folder)

      return folder.id === inboxFolder?.id
        ? <SelectedFolder key={folder.id} onDoubleClick={onClick}>{folder.name}</SelectedFolder>
        : <Folder key={folder.id} onDoubleClick={onClick}>{folder.name}</Folder>
    })}
  </LeftCol>
}
