import { LogLevel } from '@azure/msal-browser'

export const redirectPath = '/login'
export const useAppFolder = Math.random() > 1

const isLocal = window.location.href.includes('localhost')

const clientId = isLocal ? '27ca5ee9-f59c-4ce9-8735-0bb382bdf166' : '19793520-d72a-40f3-899e-44fdf898dc73'
const tenantId = isLocal ? '336c975f-2968-4e25-9d9c-09029e2a92c2' : 'e14c4d0c-ad25-410f-8f75-0450ad84932b'

const domain = isLocal ? 'http://localhost:4200' : 'https://inbox.nitrex.com'
const redirectUri = isLocal ? `${domain}${redirectPath}` : domain
const postLogoutRedirectUri = isLocal ? `${domain}/logout` : domain

export const msalConfig = {
  auth: {
    clientId,
    authority: `https://login.microsoftonline.com/${tenantId}`,
    redirectUri,
    postLogoutRedirectUri,
    navigateToLoginRequestUrl: false // If "true", will navigate back to the original request location before processing the auth code response.
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    loggerOptions: {
      loggerCallback: (level: LogLevel, message: string, containsPii?: boolean) => {
        if (containsPii) {
          // do nothing
        } else if (level === LogLevel.Error) {
          console.error(message)
        } else if (level === LogLevel.Info) {
          console.info(message)
        } else if (level === LogLevel.Verbose) {
          console.debug(message)
        } else if (level === LogLevel.Warning) {
          console.warn(message)
        }
      }
    }
  }
}

export const loginRequest = {
  scopes: ['email', '.default']
}
