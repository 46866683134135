import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { PublicClientApplication, EventType, EventMessage } from '@azure/msal-browser'
import { msalConfig } from './authConfig'

import './styles/index.css'

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
const msalInstance = new PublicClientApplication(msalConfig)

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
  // Account selection logic is app dependent. Adjust as needed for different use cases.
  msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0])
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event: EventMessage) => {
  // @ts-ignore
  const account = event.payload?.account
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    msalInstance.setActiveAccount(account)
  }
})

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(<App instance={msalInstance}/>)
