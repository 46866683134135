import styled, { createGlobalStyle, css } from 'styled-components'
import { useAppFolder } from '../authConfig'

const orange = '#E5683E'
const gray = '#F5F5F5'

const leftWidth = useAppFolder ? 0 : 240
const rightWidth = 320

const footHeight = 84
const headHeight = 120

const inlineBlock = css`
  display: inline-block;
`

const whiteOnOrange = css`
  color: white;
  background-color: ${orange};
`
const primaryButton = css`
  ${inlineBlock};
  ${whiteOnOrange};
  border: none;
  border-radius: 4px;
  padding: 1em;
  text-transform: uppercase;
  cursor: pointer;
  font-family: 'Brown Regular', sans-serif;
  font-size: 14px;
`

export const InlineDiv = styled.div`
  ${inlineBlock}
`

export const HeadLeft = styled(InlineDiv)`
  width: ${leftWidth}px;
`

export const LeftCol = styled.div`
  background-color: ${gray};
  overflow: auto;
  width: ${leftWidth}px;
`

export const PageWrapper = styled.div`
  width: calc(100vw);
  height: calc(100vh);
  overflow: hidden;
`

export const PageHead = styled.div`
  position: relative;
  color: ${orange};
  height: ${headHeight}px;
  background-image: url('/NavBackground.png');
  font-size: 24px;
  letter-spacing: 2px;
  
  div div {
    height: ${headHeight / 2}px;
    padding-left: 20px;
    padding-top: 20px;
    user-select: none;
  }
`

export const PageContent = styled.div`
  height: calc(100vh - ${headHeight}px);
  position: relative;
  > div {
    position: absolute;
  }
  a {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const Right = styled(InlineDiv)`
  width: ${rightWidth}px;
  position: absolute;
  right: 0;
`

export const FileTableWrapper = styled.div`
  height: calc(100vh - ${headHeight}px - ${footHeight}px);
  width: calc(100vw - ${leftWidth}px - ${rightWidth}px);
  overflow: auto;
`

export const FileTable = styled.table`
  width: 100%;
    
  a {
    color: black;
  }

  thead td {
    border-bottom: 1px ${gray} solid;
  }
    
  td {
    padding: 1em;
  }

  td.center {
    padding: 2em;
    text-align: center;
    user-select: none;
  }

  tr:nth-child(even) {
    background-color: ${gray};
  }
`

export const Folder = styled.div`
  padding: 1em;
  overflow: hidden;
  text-overflow: ellipsis;
  user-select: none;
`

export const SelectedFolder = styled(Folder)`
  ${whiteOnOrange}
`

export const ContentMid = styled.div`
  left: ${leftWidth}px;
  right: ${rightWidth}px;
`

export const UploadFormWrapper = styled.div`
  text-align: right;
  height: ${footHeight}px;
  padding-top: 20px;
  padding-right: 20px;
  border-top: 1px ${gray} solid;
`
export const UploadFormLabel = styled.label`${primaryButton}`

export const LogOutWrapper = styled.div`
  color: white;
  text-align: right;
  padding-right: 20px;
  font-family: 'Brown Regular', sans-serif;
  font-size: 14px;
    
  span {
    margin-right: 10px;
  }
`

export const OrangeButton = styled.button`
  ${primaryButton};
`

export const UploadFormFileInput = styled.input`
  display: none;
`

export const UploadsWrapper = styled.div`
  border-left: 1px ${gray} solid;
  right: 0;
  width: ${rightWidth}px;
`

export const UploadItem = styled.div`
  padding: 10px;
  margin-bottom: 10px;
`

export const UploadItemLabel = styled.span`
  ${inlineBlock};
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${rightWidth - 50}px;
  vertical-align: text-top;
`

export const UploadItemCancelButton = styled.button`
  cursor: pointer;
  width: 20px;
  ${inlineBlock};
  vertical-align: text-top;
  border: none;
  background: none;
`

export const UploadItemProgress = styled.progress`
  width: 100%;
  margin-top: 4px;
  height: 8px;
  color: ${orange};
  background: ${orange};
`

export const GlobalStyle = createGlobalStyle`
  ::-moz-progress-bar { background-color: ${orange}; }
  ::-webkit-progress-value { background-color: ${orange}; }
  .upper {
    text-transform: uppercase;
  }
`
